import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_7.jpg';
// Testimonials Section
import TestimonialBg from './../assets/img/jpg/section_bg_3.jpg';
import Testimonial1 from './../assets/img/jpg/reviews_avatars/testimonial_1.jpg';
import Testimonial2 from './../assets/img/jpg/reviews_avatars/testimonial_2.jpg';
import Testimonial3 from './../assets/img/jpg/reviews_avatars/testimonial_3.jpg';
import Testimonial4 from './../assets/img/jpg/reviews_avatars/testimonial_4.jpg';
import Testimonial5 from './../assets/img/jpg/reviews_avatars/testimonial_5.jpg';
import Testimonial6 from './../assets/img/jpg/reviews_avatars/testimonial_6.jpg';


const SliderTestimonial = () => {
    const settingsTestimonials = {
        dots: true,
        arrows: true,
        infinite: true,
        fade: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Slider {...settingsTestimonials}>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial1} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>The service at Prestige Jet Club is second to none. They take care of all our private air travel needs, paying attention to details!</p>
                            <p className={'name'}>Olivia Connor</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial2} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Being a member of Prestige Jet Club is a breath of fresh air. It means peace-of mind knowing that I'm not just riding on some jet, but rather one with great safety records and reliability!</p>
                            <p className={'name'}>Liam Johnson</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial3} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Prestige Jet Club is the perfect choice for any traveler looking to make their travel experience an enjoyable one. The staff are always friendly and helpful.</p>
                            <p className={'name'}>John Lewis</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial4} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>With Prestige Jet Club, I receive the highest level of services. The staff and crew are always attentive to my needs, which makes my trip an absolute dream!</p>
                            <p className={'name'}>Marie Baker</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial5} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>The safety features and excellent customer service are top priority for me when choosing my travel plans. That is why I always choose to fly with Prestige Jet Club!</p>
                            <p className={'name'}>Laura Perkins</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial6} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Prestige Jet Club never fails to exceed my expectations with their professional pilots and staff. They always take care of me and my family when we fly in, making sure that everything is going smoothly with our trip. It's nice not having to worry about travel plans.</p>
                            <p className={'name'}>Christine Renee</p>
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    );
};


export default class Page10 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>Testimonials</h3>
                    </div>
                </section>
                <section className={'testimonials'}>
                    <img src={TestimonialBg} alt="" className={'bg'}/>
                    <div className={'container'}>
                        <SliderTestimonial />
                    </div>
                </section>
            </main>
        );
    }
}