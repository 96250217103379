import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_single_3.jpg';
// Section 17 Images ==================
import Ladysuitecase from './../assets/img/jpg/getting_on_a_plane_with_luggage_3.jpg';
import LookPlanOutside from './../assets/img/jpg/couple_looking_outside_of_plane.jpg';
import PlanSelfie from './../assets/img/jpg/couple_taking_selfie_on_plane_2.jpg';
import Plan3 from './../assets/img/png/obj_plane_3.png';
import BG3 from "../assets/img/jpg/section_bg_19.jpg";
// Section 26 Images ==================
import PlanSide4 from './../assets/img/jpg/plan_side_view.jpg';
// Section 2 Images ==================
import FemaleThinking from "../assets/img/jpg/gitl_looking_outside_of_plane.jpg";





export default class Page9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero s2'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className="container">
                        <div className={'caption'}>
                            <h3 className={'main-title'}>A Look at Private Jet Pricing</h3>
                            <p>Know your options before you decide something is out of your budget! Concerning private jet pricing, there are three main tiers: basic, premium, and platinum. This article will explore each one in detail. We’ll look at the cost per hour, the amenities offered, and how to determine which level of pricing is suitable for your needs.</p>
                        </div>
                    </div>
                </section>
                <section className={'section-26'}>
                    <div className="container">
                        <img src={PlanSide4} alt="" className={'img'}/>
                        <div className="content">
                            <div>
                                <h1 className="title">Basic Pricing</h1>
                                <p>At the basic level of private jet pricing, you can expect to pay around $1,500-$2,000/hour for light or midsize jets such as a Cessna Citation CJ3 or a Bombardier Learjet 45XR. These jets normally seat between 4-7 passengers and offer essential luxuries such as snacks and refreshments. The flight attendants who staff these jets are highly trained professionals who provide high-quality customer experience.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-17'}>
                    <img src={BG3} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h1 className={'title'}>Premium Pricing</h1>
                                    <p>The next tier up from basic, introductory pricing is premium pricing. Premium-priced jets typically seat up to 12 people comfortably and offer more complete amenities such as gourmet meals, a fully stocked bar, high-quality entertainment systems, and even beds for overnight flights. Expect to pay anywhere from $2,500-$4,000/hour for this level of private jet chartering services. Some of the most famous aircrafts in this category include the Gulfstream G550 and Bombardier Challenger 850s.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={LookPlanOutside} alt="" className={'img-1'}/>
                                    <img src={PlanSelfie} alt="" className={'img-2'}/>
                                    <img src={Plan3} alt="" className={'obj'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-2 s2'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-4">
                                <img src={FemaleThinking} alt="" className={'img-fluid'}/>
                            </div>
                            <div className="col-lg-8">
                                <div className="caption ps-lg-5">
                                    <h1 className="title">Platinum Pricing</h1>
                                    <p>Finally, at the top end of private jet pricing is platinum pricing. At this tier, you can expect to pay upwards of $10,000+/hour. The most luxurious aircrafts available feature amenities such as executive seating configurations with leather seats that recline into full beds; marble bathrooms with showers; advanced entertainment systems; ultra-quiet cabins for maximum privacy; gourmet meals prepared by professional chefs; and much more! These luxuries are available for passengers at any point in time while flying. These planes are also staffed with an experienced team of pilots and attendants who have been rigorously trained in safety protocols and in providing five-star customer service. Popular models available at this tier include the Airbus ACJ319neo and Boeing Business Jet 2 (BBJ2).</p>
                                    <p>Private jets come in all shapes and sizes—and prices! When choosing a private jet chartering company, it’s important to consider what type of amenities you require before deciding on the price point. What elements do you need, and what factors can you go without? Research different companies offering various service levels so you can make an informed decision about which provider best meets your needs while still staying within budget! With careful consideration, you can find the perfect fit when booking your next flight on a private jet chartering company!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}