import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/services_banners/page_banner_18.jpg';
// Section 16 Images ==================
import LeftSideImage from "../assets/img/jpg/services_info/services_info_17.jpg";
import PlanObject from "../assets/img/png/obj_plane_bg.png";
// Section 28 Images ==================
import specificationLeftImage from "../assets/img/jpg/specification/specification_17.jpg";
import specificationEngineImage from "../assets/img/jpg/specification_bg/specification_bg_18.jpg";
// Section 14 Images ==================
import planTopIcon from "../assets/img/png/obj_plane_circle_2.png";
import PlanDottedTop from "../assets/img/png/obj_plane_dotted_top.png";
import PlanDottedBottom from "../assets/img/png/obj_plane_dotted_top.png";
// Section 29 Images ==================
import RightSideImage from "../assets/img/jpg/commodities_bg/commodities_bg_18.jpg";
import RightSideImageBG from "../assets/img/jpg/section_bg_22.jpg";
import Icon1 from "../assets/img/png/wi-fi.png";
import Icon2 from "../assets/img/png/filming.png";
import Icon3 from "../assets/img/png/chat.png";
import Icon4 from "../assets/img/png/location.png";
import Icon5 from "../assets/img/png/ipad.png";
import Icon6 from "../assets/img/png/extension_board.png";
import Icon7 from "../assets/img/png/phone.png";

// Slider Images ==================
import sliderImage1 from "../assets/img/jpg/gallery/item_1.jpg";
import sliderImage2 from "../assets/img/jpg/gallery/item_17.jpg";
import sliderImage3 from "../assets/img/jpg/gallery/item_18.jpg";
import sliderImage4 from "../assets/img/jpg/gallery/item_180.jpg";
import sliderImage5 from "../assets/img/jpg/gallery/item_181.jpg";
import sliderImage6 from "../assets/img/jpg/gallery/item_182.jpg";
import sliderImage7 from "../assets/img/jpg/gallery/item_183.jpg";
import sliderImage8 from "../assets/img/jpg/gallery/item_184.jpg";
import sliderImage9 from "../assets/img/jpg/gallery/item_185.jpg";
import sliderImage10 from "../assets/img/jpg/gallery/item_186.jpg";
import sliderImage11 from "../assets/img/jpg/gallery/item_187.jpg";
import sliderImage12 from "../assets/img/jpg/gallery/item_188.jpg";
import sliderImage13 from "../assets/img/jpg/gallery/item_189.jpg";
import sliderImage14 from "../assets/img/jpg/gallery/item_16.jpg";
import sliderImage15 from "../assets/img/jpg/gallery/item_25.jpg";
import sliderImage16 from "../assets/img/jpg/gallery/item_29.jpg";
import sliderImage17 from "../assets/img/jpg/gallery/item_30.jpg";


const SliderSlick1 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
            <div>
                <img src={sliderImage2} alt=""/>
            </div>
            <div>
                <img src={sliderImage3} alt=""/>
            </div>
            <div>
                <img src={sliderImage4} alt=""/>
            </div>
            <div>
                <img src={sliderImage5} alt=""/>
            </div>
            <div>
                <img src={sliderImage6} alt=""/>
            </div>
            <div>
                <img src={sliderImage7} alt=""/>
            </div>
            <div>
                <img src={sliderImage8} alt=""/>
            </div>
        </Slider>
    );
};
const SliderSlick2 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage9} alt=""/>
            </div>
            <div>
                <img src={sliderImage10} alt=""/>
            </div>
            <div>
                <img src={sliderImage11} alt=""/>
            </div>
            <div>
                <img src={sliderImage12} alt=""/>
            </div>
            <div>
                <img src={sliderImage13} alt=""/>
            </div>
            <div>
                <img src={sliderImage14} alt=""/>
            </div>
            <div>
                <img src={sliderImage15} alt=""/>
            </div>
            <div>
                <img src={sliderImage16} alt=""/>
            </div>
            <div>
                <img src={sliderImage17} alt=""/>
            </div>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
        </Slider>
    );
};


export default class Service18 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 2000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h5 className={'sub-title'}>Light Jets</h5>
                        <h3 className={'main-title'}>Beechcraft Premier I</h3>
                    </div>
                </section>
                <section className={'section-16 s2'}>
                    <img src={LeftSideImage} alt="" className={'left-img'}/>
                    <div className="content">
                        <p>Prestige is proud to offer exclusive private jet charter services on the Beechcraft Premier 1, a sleek and stylish 6-passenger light business jet perfect for short-haul flights. With its competitive cruise speeds, stand-up cabin, and economical fuel consumption, the Premier 1A is ideal for any business travel need. Its maximum operating altitude of 41,000 feet ensures that you can always stay above any inclement weather, while its 2,176 feet per minute climb rate gets you to your optimal cruising altitude quickly and smoothly.</p>
                    </div>
                    <img src={PlanObject} alt="" className={'obj'}/>
                </section>
                <section className={'section-28'}>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-3">
                                <div className="content-warpper">
                                    <img src={specificationLeftImage} alt=""/>
                                    <div className="content">
                                        <h1 className={'title'}>Specifications</h1>
                                        <ul className={'list'}>
                                            <li><span>Seats</span><span>6</span></li>
                                            <li><span>Hours</span><span>Up to 3</span></li>
                                            <li><span>Max Cruise Speed</span><span>530 mph</span></li>
                                            <li><span>Cabin Width</span><span>5 Ft 5 in</span></li>
                                            <li><span>Cabin Height</span><span>5 Ft 6 in</span></li>
                                            <li><span>Cabin Length</span><span>13 Ft 6 in</span></li>
                                            <li><span>Baggage Volume</span><span>23 Cu. Ft</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <img src={specificationEngineImage} alt="" className={'bg'}/>
                </section>
                <section className={'section-14 mb-0'}>
                    <div className="img-wrapper">
                        <img src={planTopIcon} alt="" className={'obj'}/>
                        <img src={PlanDottedTop} alt="" className={'top-obj'}/>
                        <img src={PlanDottedBottom} alt="" className={'bottom-obj'}/>
                    </div>
                    <div className="content">
                        <div>
                            <p>The Beechcraft Premier I offers a unique stand-up cabin experience for its class. Seating up to six passengers, it provides plenty of room to stretch out and enjoy the ride. The club seats are exceptionally comfortable, and the partitioned aft toilet is a real bonus. With its fold-down tables, the Premier I is excellent for getting work done or enjoying a meal during your flight.</p>
                        </div>
                    </div>
                </section>
                <section className={'section-5 s2'}>
                    <div className={'sliderWrapper'}>
                        <div className="thumbSlider v1">
                            <SliderSlick1 />
                        </div>
                        <div className="thumbSlider v2">
                            <SliderSlick2 />
                        </div>
                    </div>
                </section>
                <section className={'section-29'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h1 className="main-title">Commodities</h1>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon1} alt="" className={'icon'}/>
                                            <p>Wi-Fi <br/>Technology</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon2} alt="" className={'icon'}/>
                                            <p>On-Demand In-Flight Entertainment System</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon3} alt="" className={'icon'}/>
                                            <p>Text & Talk <br/>Service</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon4} alt="" className={'icon'}/>
                                            <p>Real-Time Maps and Flight Tracking with Arrival Times</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon5} alt="" className={'icon'}/>
                                            <p>iPad <br/>Tablets</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon6} alt="" className={'icon'}/>
                                            <p>Universal Power Outlets Accessible at Every Seat</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon7} alt="" className={'icon'}/>
                                            <p>Telephone</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'right-img'}>
                        <img src={RightSideImage} alt=""/>
                        <img src={RightSideImageBG} alt="" className={'bg'}/>
                    </div>
                    <img src={RightSideImageBG} alt="" className={'bg'}/>
                </section>
            </main>
        );
    }
}