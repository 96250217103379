import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_5.jpg';
import Logo from './../assets/img/png/logo/logo.png';
// Section 16 Images ==================
import FrontPlan from './../assets/img/jpg/pilot_front_of_plane.jpg';
import LearnText from './../assets/img/png/heading_learn.png';
import PlanObject from './../assets/img/png/obj_plane_bg.png';
// Section 17 Images ==================
import SeniorPilate from './../assets/img/jpg/female_pilot.jpg';
import PilotWithGlasses from './../assets/img/jpg/air_hostess_in_yellow_dress.jpg';
import Plan3 from './../assets/img/png/obj_plane_3.png';
import TextureDarkYellow from './../assets/img/jpg/section_bg_12.jpg';
// Section 18 Images ==================
import pilotWithGirl from './../assets/img/jpg/pilot_and_hostess_looking_in_sky_3.jpg';
import pilotWithGirl2 from './../assets/img/jpg/pilot_and_hostess_looking_in_sky_4.jpg';
import PlanWing from './../assets/img/jpg/plane_wing.jpg';
// Section 21 Images ==================
import PlanObject2 from './../assets/img/png/obj_plane_bg.png';
// Section 24 Images ==================
import PilotWalking from './../assets/img/jpg/pilots_with_luggage.jpg';
// Section 24 Images ==================
import Plan4 from './../assets/img/png/obj_plane_1.png';
import GlobeIconTop from './../assets/img/jpg/obj_polygon_ball_top.jpg';
import GlobeIconBottom from './../assets/img/jpg/obj_polygon_ball_bottom.jpg';
import Modal from "react-bootstrap/Modal";
import member_modal_img from "../assets/img/jpg/member_modal.jpg";
import member_diamond from "../assets/img/jpg/member_diamond.jpg";




export default class Page5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            nav1: null,
            nav2: null,
            //Concat Modal
            contact_modal: false,
        };
    }

    contact_modal_close = () => {
        this.setState({ contact_modal: false });
    };

    contact_modal_show = () => {
        this.setState({ contact_modal: true });
    };

    componentDidMount() {
        this.loaderShow();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };



    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>Learn to Fly (Certified Aviation Training)</h3>
                    </div>
                </section>
                <section className={'section-16'}>
                    <img src={FrontPlan} alt="" className={'left-img'}/>
                    <div className="content">
                        <img src={LearnText} alt="" className={'mb-5 learn-img'}/>
                        <p>If you've ever dreamed of taking to the skies, Certified Aviation Training Program is your chance to make that dream a reality. Learning to fly is an incredible experience that will give you a whole new perspective on the world. You'll be able to see unique places from an entirely new vantage point and enjoy unparalleled freedom. It's an adventure like no other and one that you'll never forget.</p>
                    </div>
                    <img src={PlanObject} alt="" className={'obj'}/>
                </section>
                <section className={'section-17'}>
                    <img src={TextureDarkYellow} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <p>At <span className={'text-yellow'}>Prestige Jet Club</span>, our Certified Aviation Training Program is an FAA-certified part 141 and part 61 program. We offer comprehensive, fast-track courses at unbeatable prices in a unique, safe environment that is second to none. Our team of highly experienced aviation trainers is passionate about helping you meet your training objectives.</p>
                                    <p>Our experienced instructors will teach you everything you need to know about flying an airplane. Then, it will be your turn to take the controls and see what it feels like to soar through the sky. Imagine yourself soaring through the sky, surrounded by nothing but blue, as you grip the airplane's controls, steering it through the clouds. So come and experience the adventure today. We guarantee that it'll be memorable! So, if you've ever dreamed of learning to fly, don't hesitate to give us a call. We'll be happy to help you turn your dream into reality.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={SeniorPilate} alt="" className={'img-1'}/>
                                    <img src={PilotWithGlasses} alt="" className={'img-2'}/>
                                    <img src={Plan3} alt="" className={'obj'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-18'}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-xl-6">
                                <div className="content">
                                   <div>
                                       <h1 className="title">Courses</h1>
                                       <p>Now you can get the best training with the highest qualifications and fly in the fastest timeline with our outstanding courses! We offer a comprehensive training program that covers both the theoretical and practical aspects of flying. Our pilot instructors will ensure that you are fully prepared to take to the skies!</p>
                                       <p>With the most up-to-date materials and resources, we provide carefully constructed programs, ensuring you receive the best training and a valuable learning experience to succeed in your chosen field. Our proven track record speaks for itself, with graduates of our programs enjoying success in their careers. If you're looking for a quality training program at a competitive price, look no further than Prestige Jet Club's Aviation Training Program. Contact us today to learn more about our programs and how we can help you achieve your goals.</p>
                                   </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="img-wrapper">
                                    <img src={pilotWithGirl} alt="" className={'img-1 d-none d-lg-block'}/>
                                    <img src={pilotWithGirl2} alt="" className={'img-1 d-lg-none'}/>
                                    <img src={PlanWing} alt="" className={'img-2'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-19'}>
                    <div className="container">
                        <h1 className={'title m-0'}>We offer a wide variety of courses to choose from</h1>
                    </div>
                </section>
                <section className={'section-20'}>
                    <div className="container">
                        <h1 className={'main-title'}>Professional Pilot Courses</h1>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h3 className="title">Professional Flight Instructor Course</h3>
                                            <h5 className="sub-title">Zero to Instructor</h5>
                                        </div>
                                        <ul>
                                            <li>TOTAL 299 hours</li>
                                            <li>70 Hour Private</li>
                                            <li>40-hour instrument</li>
                                            <li>Commercial Single & Multi Engine</li>
                                            <li>CFi, CFii, MEi</li>
                                            <li>7 Practical exams included</li>
                                            <li>6 Written exams included</li>
                                            <li>Instructor course ground in person</li>
                                            <li>CBT ground training included</li>
                                            <li>Insurance & supplies Included</li>
                                        </ul>
                                        <div>
                                            <p className={'w'}>Course based on average times
                                                (Not minimum times)
                                                Duration 12 months</p>
                                            <h2 className="price">$65,000</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h3 className="title">Commuter First Officer</h3>
                                            <h5 className="sub-title">Zero to Instructor</h5>
                                        </div>
                                        <ul>
                                            <li>500 Hours Total Time</li>
                                            <li>Single & Multi commercial with Instrument</li>
                                            <li>NO flight instructing involved</li>
                                            <li>70 hours Private</li>
                                            <li>40 hours for Instrument</li>
                                            <li>15 Hours TAA Time</li>
                                            <li>15 Hours multi engine time</li>
                                            <li>360 Hours PIC timebuilding</li>
                                            <li>4 Checkrides</li>
                                            <li>3 Written Exams</li>
                                            <li>ALL CBT ground training included</li>
                                            <li>Insurance & Supplies included</li>
                                        </ul>
                                        <div>
                                            <p className={'w'}>Course based on average times
                                                (not minimum times)
                                                Duration 10-12 month</p>
                                            <h2 className="price">$64,449</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h3 className="title">Regional First Officer</h3>
                                            <h5 className="sub-title">Airline Transport Pilot course</h5>
                                        </div>
                                        <ul>
                                            <li>1502 Hours Total Time</li>
                                            <li>Multi engine ATP with Instrument</li>
                                            <li>NO flight instructing involved</li>
                                            <li>Guaranteed interview with partner airline</li>
                                            <li>70 hours Private</li>
                                            <li>40 hours for Instrument</li>
                                            <li>15 Hours TAA Time</li>
                                            <li>52 Hours multi engine time</li>
                                            <li>1375 Hours PIC timebuilding</li>
                                            <li>5 Checkrides</li>
                                            <li>4 Written Exams</li>
                                            <li>ATP-CTP with partner</li>
                                            <li>ALL CBT ground training included</li>
                                            <li>Insurance & Supplies included</li>
                                        </ul>
                                        <div>
                                            <p className={'w'}>Course based on average times
                                                (Not minimum times)
                                                Duration 12 months</p>
                                            <h2 className="price">$65,000</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-21'}>
                    <div className="container">
                        <h1 className={'main-title'}>Part 141 Courses</h1>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card">
                                    <img src={PlanObject2} alt="" className={'icon'}/>
                                    <div className="content">
                                        <div>
                                            <h1 className="title">Private Pilot</h1>
                                        </div>
                                        <ul>
                                            <li>40 Hours Cessna 172</li>
                                            <li>55 hours dual instruction & ground</li>
                                            <li>1 Written Exam</li>
                                            <li>1 Practical Exam</li>
                                            <li>1 Online ground school</li>
                                            <li>Based on FAA 141 minimums</li>
                                            <li>4-8 weeks duration</li>
                                        </ul>
                                        <div>
                                            <h2 className="price">$11,115</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <img src={PlanObject2} alt="" className={'icon'}/>
                                    <div className="content">
                                        <div>
                                            <h1 className="title">Instrument Rating</h1>
                                        </div>
                                        <ul>
                                            <li>35 Hours Cessna 172</li>
                                            <li>50 hours dual instruction & ground</li>
                                            <li>1 Written Exam</li>
                                            <li>1 Practical Exam</li>
                                            <li>1 Online ground school</li>
                                            <li>Based on FAA 141 minimums</li>
                                            <li>4-6 weeks duration</li>
                                        </ul>
                                        <div>
                                            <h2 className="price">$9,990</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <img src={PlanObject2} alt="" className={'icon'}/>
                                    <div className="content">
                                        <div>
                                            <h1 className="title">Private Pilot</h1>
                                        </div>
                                        <ul>
                                            <li>120 Hours single engine aircraft</li>
                                            <li>70 hours dual instruction & ground</li>
                                            <li>1 Written Exam</li>
                                            <li>1 Practical Exam</li>
                                            <li>1 Online ground school</li>
                                            <li>Based on FAA 141 minimums</li>
                                            <li>3 months duration</li>
                                        </ul>
                                        <div>
                                            <h2 className="price">$25,215</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-22'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h1 className="title text-truncate">Multi-Engine Commercial</h1>
                                            <h3 className="sub-title">Add On</h3>
                                        </div>
                                        <ul>
                                            <li>5 hours of Dual instruction at $365 = $1,825</li>
                                            <li>15 hours of ground instruction at $60 = $900</li>
                                            <li>Check-ride with FAA exa miner = $800</li>
                                        </ul>
                                        <div>
                                            <h6 className={'total'}>Total</h6>
                                            <h2 className="price">$3,525</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h1 className="title text-truncate">Multi-Engine Commercial</h1>
                                            <h3 className="sub-title">Add On</h3>
                                        </div>
                                        <ul>
                                            <li>15 hours of Dual instruction</li>
                                            <li>35 hours of Ground Instruction</li>
                                            <li>2 Written Tests</li>
                                            <li>1 Practical</li>
                                        </ul>
                                        <div>
                                            <h6 className={'total'}>Total</h6>
                                            <h2 className="price">$6,570</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-23'}>
                    <div className="container">
                        <h1 className={'main-title'}>Discovery Flights</h1>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h1 className="title">Discovery Flight</h1>
                                        </div>
                                        <p>If you've ever dreamed of taking to the skies, this is your chance to make that dream a reality. For just $210, you can take a flight near the airport and see firsthand what it's like to be a pilot. You'll get to fly down the beautiful beaches of Fort Lauderdale and learn to take control of the aircraft. This is an excellent opportunity to try something new and exciting, so don't miss your chance. Book today and take to the skies!</p>
                                        <div>
                                            <h2 className="price">$210</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="content">
                                        <div>
                                            <h1 className="title">Discovery Flight</h1>
                                        </div>
                                        <p>If you've ever dreamed of taking to the skies, this is your chance to make that dream a reality. For just $210, you can take a flight near the airport and see firsthand what it's like to be a pilot. You'll get to fly down the beautiful beaches of Fort Lauderdale and learn to take control of the aircraft. This is an excellent opportunity to try something new and exciting, so don't miss your chance. Book today and take to the skies!</p>
                                        <div>
                                            <h2 className="price">$210</h2>
                                            <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-24'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="card">
                                    <img src={PilotWalking} alt="" className={'img'}/>
                                    <div className="content">
                                        <h1 className="title">Pilot Refresher Course</h1>
                                        <p>If you're looking to sharpen your skills as a pilot, our $1500 Flight Training Course is perfect for you. You'll get 6 hours of dual flight instruction and 4 hours of ground time dedicated to maneuvering flight, instrument conditions, and emergencies. It's perfect for flight reviews and will help you become a better, more confident pilot. So, if you're ready to improve your flying skills, sign up for this course today. You won't regret it!</p>
                                        <h2 className="price">$1500</h2>
                                        <button className={'btn btn-outline-primary btn-md'} onClick={this.contact_modal_show}>Contact Us</button>
                                    </div>
                                    <img src={Plan4} alt="" className={'obj'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={GlobeIconBottom} alt="" className={'obj-top'}/>
                    <img src={GlobeIconTop} alt="" className={'obj-bottom'}/>
                </section>
                <Modal show={this.state.contact_modal} onHide={this.contact_modal_close} className={'contact_modal'} centered>
                    <Modal.Header closeButton>
                        <img src={Logo} alt=""/>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title>Contact Us</Modal.Title>
                        <iframe src="https://link.rewardlion.com/widget/form/IvtZZpKH3oWLgc6aM7Lm"
                                id="inline-IvtZZpKH3oWLgc6aM7Lm"
                                data-layout="{'id':'INLINE'}"
                                data-trigger-type="alwaysShow"
                                data-trigger-value=""
                                data-activation-type="alwaysActivated"
                                data-activation-value=""
                                data-deactivation-type="neverDeactivate"
                                data-deactivation-value=""
                                data-form-name="Contact Us Form"
                                data-height="616"
                                data-layout-iframe-id="inline-IvtZZpKH3oWLgc6aM7Lm"
                                data-form-id="IvtZZpKH3oWLgc6aM7Lm">
                        </iframe>
                        <script src="https://link.rewardlion.com/js/form_embed.js"></script>
                    </Modal.Body>
                </Modal>
            </main>
        );
    }
}