import React  from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Layout from "./pages/Layout";
import Home from './pages/Home';

import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import Page5 from './pages/Page5';
import Page6 from './pages/Page6';
import Page7 from './pages/Page7';
import Page8 from './pages/Page8';
import Page9 from './pages/Page9';
import Page10 from './pages/Page10';
import Page11 from './pages/Page11';


import Service1 from './pages/Service1';
import Service2 from './pages/Service2';
import Service3 from './pages/Service3';
import Service4 from './pages/Service4';
import Service5 from './pages/Service5';
import Service6 from './pages/Service6';
import Service7 from './pages/Service7';
import Service8 from './pages/Service8';
import Service9 from './pages/Service9';
import Service10 from './pages/Service10';
import Service11 from './pages/Service11';
import Service12 from './pages/Service12';
import Service13 from './pages/Service13';
import Service14 from './pages/Service14';
import Service15 from './pages/Service15';
import Service16 from './pages/Service16';
import Service17 from './pages/Service17';
import Service18 from './pages/Service18';
import Service19 from './pages/Service19';


import GetQuote from './pages/GetQuote';







function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="charter" element={<Page1 />} />
            <Route path="membership" element={<Page2 />} />
            <Route path="safety-and-certificates" element={<Page3 />} />
            <Route path="about-us" element={<Page4 />} />
            <Route path="learn-how-to-fly" element={<Page5 />} />
            <Route path="blogs" element={<Page6 />} />
            <Route path="get-a-quote" element={<GetQuote />} />
            <Route path="fly-in-style-and-comfort" element={<Page7 />} />
            <Route path="taking-flight-with-prestige-jet-club-courses" element={<Page8 />} />
            <Route path="look-at-private-jet-pricing" element={<Page9 />} />
            <Route path="testimonials" element={<Page10 />} />
            <Route path="thankyou" element={<Page11 />} />

            <Route path="bombardier-global-8000" element={<Service1 />} />
            <Route path="gulf-stream-G550" element={<Service2 />} />
            <Route path="bombardier-global-7500" element={<Service3 />} />
            <Route path="bombardier-global-6000" element={<Service4 />} />
            <Route path="global-express" element={<Service5 />} />
            <Route path="bombardier-global-5500" element={<Service6 />} />

            <Route path="airbus-A380" element={<Service7 />} />
            <Route path="boeing-business-jet-bbj-737-max" element={<Service8 />} />
            <Route path="airbus-a330neo" element={<Service9 />} />
            <Route path="bombardier-challenger-650" element={<Service10 />} />

            <Route path="bombardier-challenger-601" element={<Service11 />} />
            <Route path="bombardier-challenger-350" element={<Service12 />} />
            <Route path="cessna-citation-longitude" element={<Service13 />} />

            <Route path="cessna-citation-sovereign" element={<Service14 />} />
            <Route path="cessna-citation-excel" element={<Service15 />} />
            <Route path="cessna-citation-xsl" element={<Service16 />} />

            <Route path="embraer-phenom-300-e" element={<Service17 />} />
            <Route path="beech-craft-premier-i" element={<Service18 />} />
            <Route path="cirrus-vision-jet" element={<Service19 />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;


