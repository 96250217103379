import React, { Component } from "react";
import './../assets/scss/component/footer.scss';
import { Link } from "react-router-dom";
// Images ==================
import Logo from './../assets/img/png/logo/logo.png';
import {Container} from "react-bootstrap";


export default class Footer extends Component {
    pageTop() {
        window.scrollTo(0, 0);
    }
    render() {
        const year = new Date().getFullYear();
        return (
            <div className="footer">
                <Link className={'logo'} to="/" onClick={this.pageTop}>
                    <img src={Logo} alt="Prestige" />
                </Link>
                <ul className={'menu'}>
                    <li><Link to="/" onClick={this.pageTop}>Home</Link></li>
                    <li><Link to="/charter" onClick={this.pageTop}>Charter</Link></li>
                    <li><Link to="/about-us" onClick={this.pageTop}>About</Link></li>
                    <li><Link to="/membership" onClick={this.pageTop}>Membership</Link></li>
                    <li><Link to="/get-a-quote" onClick={this.pageTop}>Get a Quote</Link></li>
                </ul>
                <div className="bottom">
                    <p>© All Rights Reserved Prestige Jet Club Powered by RewardLion {year}</p>
                </div>
            </div>
        );
    }
}