import React, { Component } from "react";
import {Form, Container, Button} from 'react-bootstrap';
// import './../assets/scss/home.scss';

// Images ==================
// Slider Images --
import BannerImage1 from './../assets/img/jpg/page_banners/page_banner_1.jpg';




export default class GetQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }

    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };
    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero'}>
                    <img src={BannerImage1} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>Get a Quote</h3>
                    </div>
                </section>
                <section className={'section-6 quote-section'}>
                    <Container>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                {/*<Form className={'form'}>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Departure Airport/City*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Airport Name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Departure Airport/City*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Airport Name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-12">*/}
                                {/*            <div className="text-center mb-5">*/}
                                {/*                <Button variant="primary" size="lg">Calculate</Button>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <p>We will get back to you with your itinerary as soon as possible. If you would like immediate assistance, please contact us at <a*/}
                                {/*        href="tel:+1-800-000-0000">+1-800-000-0000</a></p>*/}
                                {/*    <br/>*/}
                                {/*    <div className={'mb-5'}>*/}
                                {/*        <h3 className={'title'}>Who is flying?*</h3>*/}
                                {/*        <Form.Check*/}
                                {/*            type={'radio'}*/}
                                {/*            id={`radio-1`}*/}
                                {/*            label={`I am flying`}*/}
                                {/*        />*/}
                                {/*        <Form.Check*/}
                                {/*            type={'radio'}*/}
                                {/*            id={`radio-1`}*/}
                                {/*            label={`I am booking for someone else`}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>First Name*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Please enter your first name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Last Name*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Please enter your last name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Email*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Please enter your email" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Phone number*</Form.Label>*/}
                                {/*                <Form.Control type="email" placeholder="Please enter your phone Number" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <div className={'mb-5'}>*/}
                                {/*                <h3 className={'title'}>Your Time Zone</h3>*/}
                                {/*                <p className={'open-sans'}>We will be in touch based on your location.</p>*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`West (PST)`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Mountain (MT)`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Central (CST)`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`East (EST)`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Outside of the U.S.`}*/}
                                {/*                />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <div className={'mb-5'}>*/}
                                {/*                <h3 className={'title'}>Preferred Contact Method</h3>*/}
                                {/*                <p className={'open-sans'}>Select all that apply & provide your information below.</p>*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'checkbox'}*/}
                                {/*                    id={`checkbox-1`}*/}
                                {/*                    label={`Email`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'checkbox'}*/}
                                {/*                    id={`checkbox-2`}*/}
                                {/*                    label={`Call`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'checkbox'}*/}
                                {/*                    id={`checkbox-3`}*/}
                                {/*                    label={`Text`}*/}
                                {/*                />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-12">*/}
                                {/*            <div className={'mb-5'}>*/}
                                {/*                <h3 className={'title'}>Type of Trip*</h3>*/}
                                {/*                <p className={'open-sans'}>How many legs do you need?</p>*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`One Way`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Special Request`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Round Trip`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`No Trip Yet, Request More Info`}*/}
                                {/*                />*/}
                                {/*                <Form.Check*/}
                                {/*                    type={'radio'}*/}
                                {/*                    id={`radio-2`}*/}
                                {/*                    label={`Multi-Leg`}*/}
                                {/*                />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Depart</Form.Label>*/}
                                {/*                <Form.Control type="date" placeholder="Airport Name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Return</Form.Label>*/}
                                {/*                <Form.Control type="date" placeholder="Airport Name" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6">*/}
                                {/*            <h3 className={'title mb-4'}>Number Of Passengers</h3>*/}
                                {/*            <Form.Group className="form-group" controlId="formBasicEmail">*/}
                                {/*                <Form.Label>Passengers</Form.Label>*/}
                                {/*                <Form.Control type="number" placeholder="Number Of Passengers" />*/}
                                {/*            </Form.Group>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <Button className={'mx-auto d-block'} type={'submit'} variant="primary" size="xl">Submit</Button>*/}
                                {/*</Form>*/}
                                <div className="form_wrapper">
                                    <iframe className={'get_quote_iframe'} src="https://link.rewardlion.com/widget/form/gpFMpGgGxaSwXtlJirPc" id="inline-gpFMpGgGxaSwXtlJirPc" data-layout="{'id':'INLINE'}" data-trigger-type="alwaysShow" data-trigger-value="" data-activation-type="alwaysActivated" data-activation-value="" data-deactivation-type="neverDeactivate" data-deactivation-value="" data-form-name="Request your charter" data-height="2027" data-layout-iframe-id="inline-gpFMpGgGxaSwXtlJirPc" data-form-id="gpFMpGgGxaSwXtlJirPc">
                                    </iframe>
                                    <script src="https://link.rewardlion.com/js/form_embed.js"></script>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        );
    }
}