import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_single_1.jpg';
// Section 6 Images ==================
import VIPCustomer from './../assets/img/jpg/plane_happy_passenger_wide.jpg';
// Section 17 Images ==================
import Ladysuitecase from './../assets/img/jpg/getting_on_a_plane_with_luggage_2.jpg';
import FemaleWithPlan from './../assets/img/jpg/plane_hostess_looking_something.jpg';
import Plan3 from './../assets/img/png/obj_plane_3.png';
import BG3 from "../assets/img/jpg/section_bg_18.jpg";
// Section 2 Images ==================
import HappyCouple2 from "../assets/img/jpg/old_couple_happy.jpg";




export default class Page7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero s2'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className="container">
                        <div className={'caption'}>
                            <h3 className={'main-title'}>Fly in Style & Comfort with Our Exclusive Membership Program</h3>
                            <p>
                                Have you ever dreamed of flying in style with access to the highest levels of personal service?
                                Look no further than Prestige Jet Club's Exclusive Membership Program! As a member of our esteemed card program, you'll never need to worry about your travel plans when taking business trips or enjoying leisurely vacations.
                                We offer an unparalleled suite of benefits that make each journey extraordinary. Our members enjoy competitive prices, increased flexibility, VIP perks, and seamless booking experiences – making it easy to explore the world! Travel in style, luxury, and comfort with Prestige Jet Club!
                                <br/>Discover our three membership options that give access to the entire fleet!
                            </p>
                        </div>
                    </div>
                </section>
                <section className={'section-26'}>
                    <div className="container">
                        <img src={VIPCustomer} alt="" className={'img'}/>
                        <div className="content">
                            <div>
                                <h1 className="title">Silver Stature Card</h1>
                                <p>Fly like royalty and access an idyllic aviation experience with our Stature Card. A top-tier membership, this card connects you to the jet of your dreams - allowing for interchangeable options, so no flight is ever a compromise on style or comfort! With our 15 to 100-hour options, you can purchase time according to your needs and recharge when needed.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-17'}>
                    <img src={BG3} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h1 className={'title'}>Gold Esteem Card</h1>
                                    <p>The Esteem Card from Prestige Jet Club is the perfect choice for frequent business commuters looking to make their journeys faster and more luxurious. With our deposit-based program, you can choose a plane that best fits your journey's needs and deduct occupied time based on its hourly rate. We guarantee a streamlined booking process so that every second spent traveling counts towards achieving important goals. Make sure to take advantage today!</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={Ladysuitecase} alt="" className={'img-1'}/>
                                    <img src={FemaleWithPlan} alt="" className={'img-2'}/>
                                    <img src={Plan3} alt="" className={'obj'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-2 s2'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <img src={HappyCouple2} alt="" className={'img-fluid'}/>
                            </div>
                            <div className="col-lg-6">
                                <div className="caption ps-lg-5">
                                    <h1 className="title">Diamond Elite Card</h1>
                                    <p>Enjoy all the benefits of Prestige Jet Club membership with an Elite Card, such as access to our worldwide network of hand-selected aircraft operators and 24/7 concierge services. Our specially-crafted package allows Members to experience more flexible rates and additional discounts on select services and products. Take your travel plans to new heights with our Elite Card!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-27'}>
                    <div className="container">
                        <p>Make all your dreams come true with Prestige Jet Club's Membership Card!</p>
                    </div>
                </section>
            </main>
        );
    }
}