import React, { Component, props } from "react";
import { Link } from "react-router-dom";


// Images ==================
// Slider Images --
// Preloader Images --
import Check from './../assets/img/png/check-mark.png';


export default class Home extends Component {
    render() {
        return (
            <main>
                <div className="container">
                    <section className={'thankyou-wrapper'}>
                        <div className="content">
                            <img src={Check} alt="" className={'icon'}/>
                            <h1 className="title">Thank You!</h1>
                            <p>We will get back to you with your itinerary as soon as possible. If you would like immediate assistance, please contact us at (954) 951-8895</p>
                            <Link to={'/'} className={'btn btn-primary btn-lg text-uppercase'}>Go Home</Link>
                        </div>
                    </section>
                </div>
            </main>
        );
    }
}