import React, { Component  } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
// import './../assets/scss/home.scss';
import $ from "jquery";

// Images ==================
import BannerImage2 from './../assets/img/jpg/page_banners/page_banner_2.jpg';
// Section 8
import PlanImg2 from './../assets/img/png/obj_plane_2.png';
import Lady from './../assets/img/jpg/plane_using_laptop.jpg';
import Selfie from './../assets/img/jpg/plane_posing_at_door.jpg';
import PlanObj from './../assets/img/png/obj_plane_dotted_top.png';
import CityBG from './../assets/img/jpg/section_bg_16.jpg';
// Section 9
import CloudsBG from './../assets/img/jpg/section_bg_23.jpg';
import Card1 from './../assets/img/png/card_membership_silver.png';
import Card2 from './../assets/img/png/card_membership_gold.png';
import Card3 from './../assets/img/png/card_membership_diamond.png';
// Modal
import member_modal_img from './../assets/img/jpg/member_modal.jpg';
import member_silver from './../assets/img/jpg/member_silver.jpg';
import member_gold from './../assets/img/jpg/member_gold.jpg';
import member_diamond from './../assets/img/jpg/member_diamond.jpg';
import Logo from "../assets/img/png/logo/logo.png";


export default class Page2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //state for sliver modal
            membership_sliver: false,
            //state for gold modal
            membership_gold: false,
            //state for diamond modal
            membership_diamond: false,
            //state for diamond modal
            membership_form: false,
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }

    membership_sliver_close = () => {
        this.setState({ membership_sliver: false });
    };

    membership_sliver_show = () => {
        this.setState({ membership_sliver: true });
    };

    membership_gold_close = () => {
        this.setState({ membership_gold: false });
    };

    membership_gold_show = () => {
        this.setState({ membership_gold: true });
    };

    membership_diamond_close = () => {
        this.setState({ membership_diamond: false });
    };

    membership_diamond_show = () => {
        this.setState({ membership_diamond: true });
    };

    membership_form_close = () => {
        this.setState({ membership_form: false });
    };

    membership_form_show = () => {
        this.setState({ membership_form: true});
        this.setState({  membership_sliver: false, membership_gold: false, membership_diamond: false });
    };
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };



    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage2} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>Your Choice of Three Prestige Card-Holder <br/> Memberships</h3>
                    </div>
                </section>
                <section className={'section-8'}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="card">
                                    <p className="m-0">For those who prefer the freedom to fly on their schedule with no long-term commitment, Prestige Jet Club offers three different membership options that give access to the entire fleet. The minimum upfront investment with the ability to replenish Club Cards anytime makes this club the best choice for frequent private jet travelers. As a member, you'll be able to take advantage of exclusive benefits and rewards, receive first-class customer service, and enjoy unparalleled flexibility when it comes to flying.</p>
                                    <img src={PlanImg2} alt="" className={'obj'}/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xxl-5 order-1 order-lg-2">
                                <div className="img-wrapper">
                                    <div className="thumb">
                                        <img src={Lady} alt=""/>
                                    </div>
                                    <div className="thumb">
                                        <img src={Selfie} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={PlanObj} alt="" className={'obj'}/>
                    <img src={CityBG} alt="" className={'bg'}/>
                </section>
                <section className={'section-9'}>
                    <h1 className="main-title">Prestige Memberships</h1>
                    <div className="container">
                        <div className="cards-wrapper">
                            <div className="card">
                                <div className="img-wrapper">
                                    <img src={Card1} alt=""/>
                                    <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_sliver_show}>View Details</button>
                                </div>
                            </div>
                            <div className="card card2">
                                <div className="img-wrapper">
                                    <img src={Card2} alt=""/>
                                    <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_gold_show}>View Details</button>
                                </div>
                            </div>
                            <div className="card card3">
                                <div className="img-wrapper">
                                    <img src={Card3} alt=""/>
                                    <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_diamond_show}>View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={CloudsBG} alt="" className={'bg'}/>
                </section>

                <Modal show={this.state.membership_sliver} onHide={this.membership_sliver_close} className={'member_modal'} centered>
                    <Modal.Header closeButton>
                        <img src={member_modal_img} alt=""/>
                    </Modal.Header>
                    <Modal.Body>
                        <img className={'modal_bg'} src={member_silver} alt=""/>
                        <Modal.Title><span>Silver</span> Esteem Card</Modal.Title>
                        <p>If you're a frequent business commuter, the Esteem Card from Prestige Jet Club is the perfect way to make the most of our fleet. With this deposit-based program, you can choose the aircraft that best suits your travel needs and deduct the occupied hours based on the hourly rate for that specific aircraft type. So whether you're looking for a fast and convenient way to get to your next meeting or a luxurious way to meet on the go, the Esteem Card has you covered. And because we know your time is precious, we offer a hassle-free booking experience so you can focus on what's important.</p>
                        <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_form_show}>Be a Member</button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.membership_gold} onHide={this.membership_gold_close} className={'member_modal'} centered>
                    <Modal.Header closeButton>
                        <img src={member_modal_img} alt=""/>
                    </Modal.Header>
                    <Modal.Body>
                        <img className={'modal_bg'} src={member_gold} alt=""/>
                        <Modal.Title><span>Gold</span> Esteem Card</Modal.Title>
                        <p>If you're a frequent business commuter, the Esteem Card from Prestige Jet Club is the perfect way to make the most of our fleet. With this deposit-based program, you can choose the aircraft that best suits your travel needs and deduct the occupied hours based on the hourly rate for that specific aircraft type. So whether you're looking for a fast and convenient way to get to your next meeting or a luxurious way to meet on the go, the Esteem Card has you covered. And because we know your time is precious, we offer a hassle-free booking experience so you can focus on what's important.</p>
                        <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_form_show}>Be a Member</button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.membership_diamond} onHide={this.membership_diamond_close} className={'member_modal'} centered>
                    <Modal.Header closeButton>
                        <img src={member_modal_img} alt=""/>
                    </Modal.Header>
                    <Modal.Body>
                        <img className={'modal_bg'} src={member_diamond} alt=""/>
                        <Modal.Title><span>Diamond</span> Esteem Card</Modal.Title>
                        <p>If you're a frequent business commuter, the Esteem Card from Prestige Jet Club is the perfect way to make the most of our fleet. With this deposit-based program, you can choose the aircraft that best suits your travel needs and deduct the occupied hours based on the hourly rate for that specific aircraft type. So whether you're looking for a fast and convenient way to get to your next meeting or a luxurious way to meet on the go, the Esteem Card has you covered. And because we know your time is precious, we offer a hassle-free booking experience so you can focus on what's important.</p>
                        <button className={'btn btn-outline-light btn-lg'} onClick={this.membership_form_show}>Be a Member</button>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.membership_form} onHide={this.membership_form_close} className={'contact_modal'} centered>
                    <Modal.Header closeButton>
                        <img src={Logo} alt=""/>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal.Title>Be a Member</Modal.Title>
                        <iframe
                            src="https://link.rewardlion.com/widget/form/PcBLKGiivcS9ZntJVdtP"
                            id="inline-PcBLKGiivcS9ZntJVdtP"
                            data-layout="{'id':'INLINE'}"
                            data-trigger-type="alwaysShow"
                            data-trigger-value=""
                            data-activation-type="alwaysActivated"
                            data-activation-value=""
                            data-deactivation-type="neverDeactivate"
                            data-deactivation-value=""
                            data-form-name="Be A Member Form"
                            data-height="614"
                            data-layout-iframe-id="inline-PcBLKGiivcS9ZntJVdtP"
                            data-form-id="PcBLKGiivcS9ZntJVdtP">
                        </iframe>
                        <script src="https://link.rewardlion.com/js/form_embed.js"></script>

                    </Modal.Body>
                </Modal>
            </main>
        );
    }
}