import React, { Component, props } from "react";
import {Carousel} from "react-bootstrap";
import $ from "jquery";
import Slider from "react-slick";
import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


// Images ==================
// Slider Images --
// Preloader Images --
import Slide_First_Desktop from './../assets/img/jpg/home_slides/slide_first_desktop.jpg';
import Slide_First_Tablet from './../assets/img/jpg/home_slides/slide_first_tablet.jpg';
import Slide_First_Mobile from './../assets/img/jpg/home_slides/slide_first_mobile.jpg';
import Slide_Second_Desktop from './../assets/img/jpg/home_slides/slide_second_desktop.jpg';
import Slide_Second_Tablet from './../assets/img/jpg/home_slides/slide_second_tablet.jpg';
import Slide_Second_Mobile from './../assets/img/jpg/home_slides/slide_second_mobile.jpg';
import Slide_Third_Desktop from './../assets/img/jpg/home_slides/slide_third_desktop.jpg';
import Slide_Third_Tablet from './../assets/img/jpg/home_slides/slide_third_tablet.jpg';
import Slide_Third_Mobile from './../assets/img/jpg/home_slides/slide_third_mobile.jpg';
import Slide_Fourth_Desktop from './../assets/img/jpg/home_slides/slide_fourth_desktop.jpg';
import Slide_Fourth_Tablet from './../assets/img/jpg/home_slides/slide_fourth_tablet.jpg';
import Slide_Fourth_Mobile from './../assets/img/jpg/home_slides/slide_fourth_mobile.jpg';
import ScrollDownArrow from './../assets/img/png/icon_scroll_down_arrow.png';
// Section 1
import PolygonLeft from './../assets/img/png/obj_polygon_left.png';
import PolygonRight from './../assets/img/png/obj_polygon_right.png';
import PlanImg from './../assets/img/png/obj_plane_1.png';
// Section 2
import Pilot from './../assets/img/jpg/plane_happy_pilot.jpg';
import PlanGif from './../assets/img/jpg/section_bg_1.jpg';
// Section 3
import Customer from './../assets/img/jpg/plane_happy_passenger.jpg';
// Section 4
import Crew from './../assets/img/jpg/section_bg_2.jpg';
import Pilot2 from './../assets/img/jpg/pilot_and_hostess_looking_in_sky.jpg';
// Section 5
import Prestige from './../assets/img/png/heading_prestige.png';
import Club from './../assets/img/png/heading_club.png';
// Thumb Slider Images
import sliderImage1 from './../assets/img/jpg/gallery/item_1.jpg';
import sliderImage2 from './../assets/img/jpg/gallery/item_2.jpg';
import sliderImage3 from './../assets/img/jpg/gallery/item_3.jpg';
import sliderImage4 from './../assets/img/jpg/gallery/item_4.jpg';
import sliderImage5 from './../assets/img/jpg/gallery/item_5.jpg';
import sliderImage6 from './../assets/img/jpg/gallery/item_6.jpg';
import sliderImage7 from './../assets/img/jpg/gallery/item_7.jpg';
import sliderImage8 from './../assets/img/jpg/gallery/item_8.jpg';
import sliderImage9 from './../assets/img/jpg/gallery/item_9.jpg';
import sliderImage10 from './../assets/img/jpg/gallery/item_10.jpg';
import sliderImage11 from './../assets/img/jpg/gallery/item_11.jpg';
import sliderImage12 from './../assets/img/jpg/gallery/item_12.jpg';
import sliderImage13 from './../assets/img/jpg/gallery/item_13.jpg';
import sliderImage14 from './../assets/img/jpg/gallery/item_14.jpg';
import sliderImage15 from './../assets/img/jpg/gallery/item_15.jpg';
import sliderImage16 from './../assets/img/jpg/gallery/item_16.jpg';
// Testimonials Section
import TestimonialBg from './../assets/img/jpg/section_bg_3.jpg';
import Testimonial1 from './../assets/img/jpg/reviews_avatars/testimonial_1.jpg';
import Testimonial2 from './../assets/img/jpg/reviews_avatars/testimonial_2.jpg';
import Testimonial3 from './../assets/img/jpg/reviews_avatars/testimonial_3.jpg';
import Testimonial4 from './../assets/img/jpg/reviews_avatars/testimonial_4.jpg';
import Testimonial5 from './../assets/img/jpg/reviews_avatars/testimonial_5.jpg';
import Testimonial6 from './../assets/img/jpg/reviews_avatars/testimonial_6.jpg';
import AOS from "aos";
import loaderImage from "../assets/img/png/logo/preloader_screen.png";


const SliderSlick1 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
            <div>
                <img src={sliderImage2} alt=""/>
            </div>
            <div>
                <img src={sliderImage3} alt=""/>
            </div>
            <div>
                <img src={sliderImage4} alt=""/>
            </div>
            <div>
                <img src={sliderImage5} alt=""/>
            </div>
            <div>
                <img src={sliderImage6} alt=""/>
            </div>
            <div>
                <img src={sliderImage7} alt=""/>
            </div>
            <div>
                <img src={sliderImage8} alt=""/>
            </div>
        </Slider>
    );
};
const SliderSlick2 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage9} alt=""/>
            </div>
            <div>
                <img src={sliderImage10} alt=""/>
            </div>
            <div>
                <img src={sliderImage11} alt=""/>
            </div>
            <div>
                <img src={sliderImage12} alt=""/>
            </div>
            <div>
                <img src={sliderImage13} alt=""/>
            </div>
            <div>
                <img src={sliderImage14} alt=""/>
            </div>
            <div>
                <img src={sliderImage15} alt=""/>
            </div>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
            <div>
                <img src={sliderImage16} alt=""/>
            </div>
        </Slider>
    );
};
const SliderTestimonial = () => {
    const settingsTestimonials = {
        dots: true,
        arrows: true,
        infinite: true,
        fade: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Slider {...settingsTestimonials}>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial1} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>The service at Prestige Jet Club is second to none. They take care of all our private air travel needs, paying attention to details!</p>
                            <p className={'name'}>Olivia Connor</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial2} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Being a member of Prestige Jet Club is a breath of fresh air. It means peace-of mind knowing that I'm not just riding on some jet, but rather one with great safety records and reliability!</p>
                            <p className={'name'}>Liam Johnson</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial3} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Prestige Jet Club is the perfect choice for any traveler looking to make their travel experience an enjoyable one. The staff are always friendly and helpful.</p>
                            <p className={'name'}>John Lewis</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial4} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>With Prestige Jet Club, I receive the highest level of services. The staff and crew are always attentive to my needs, which makes my trip an absolute dream!</p>
                            <p className={'name'}>Marie Baker</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial5} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>The safety features and excellent customer service are top priority for me when choosing my travel plans. That is why I always choose to fly with Prestige Jet Club!</p>
                            <p className={'name'}>Laura Perkins</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={''}>
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="slide-image" data-aos="fade-up-right" data-aos-duration="1000">
                            <img src={Testimonial6} alt=""/>
                        </div>
                    </div>
                    <div className={'col-lg-8'}>
                        <div className={'slide-box'} data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000">
                            <p>Prestige Jet Club never fails to exceed my expectations with their professional pilots and staff. They always take care of me and my family when we fly in, making sure that everything is going smoothly with our trip. It's nice not having to worry about travel plans.</p>
                            <p className={'name'}>Christine Renee</p>
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    );
};


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderRunning: false
        };
        this.sliderStart = this.sliderStart.bind(this);
    }

    sliderStart() {
        this.setState({ sliderRunning: true });
    }

    componentDidMount() {
        $(".main-slider .scroll_down_to_first_section").on('click',function () {
            $('html,body').animate({
                scrollTop: $("#first_section").offset().top}, 300);
        });

        const siteUrl = window.location.origin;
        const currentPageUrl = window.location.href;
        const lastSegment = currentPageUrl.split("/").pop();
        if (lastSegment === 'thankyou') {

        }
    }


    render() {
        return (
            <main>
                <section className={'main-slider'}>
                    <Carousel controls={false} indicators={false} fade={true} pause={false} interval={this.state.sliderRunning}>
                        <Carousel.Item interval={7000} className={'slide-1'}>
                            <img src={Slide_First_Desktop} alt="First slide" className={'slider-img desktop'}/>
                            <img src={Slide_First_Tablet} alt="First slide" className={'slider-img tablet'}/>
                            <img src={Slide_First_Mobile} alt="First slide" className={'slider-img mobile'}/>
                            <Carousel.Caption>
                                <div className="container">
                                    <div className="overflow-hidden">
                                        <h3 className={'main-title'}>Welcome to the<br/>Prestige Jet Club!</h3>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={7000} className={'slide-2'}>
                            <img src={Slide_Second_Desktop} alt="First slide" className={'slider-img desktop'}/>
                            <img src={Slide_Second_Tablet} alt="First slide" className={'slider-img tablet'}/>
                            <img src={Slide_Second_Mobile} alt="First slide" className={'slider-img mobile'}/>
                            <Carousel.Caption>
                                <div className="container">
                                    <div className="overflow-hidden">
                                        <h3 className={'main-title'}>We provide the best aircraft in each <br/>class without compromise. </h3>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={7000} className={'slide-3'}>
                            <img src={Slide_Third_Desktop} alt="First slide" className={'slider-img desktop'}/>
                            <img src={Slide_Third_Tablet} alt="First slide" className={'slider-img tablet'}/>
                            <img src={Slide_Third_Mobile} alt="First slide" className={'slider-img mobile'}/>
                            <Carousel.Caption>
                                <div className="container">
                                    <div className="overflow-hidden">
                                        <h3 className={'main-title'}>Our team of dedicated, highly qualified <br/>
                                            professionals across safety, service, <br/>
                                            operations, and more ensure that our <br/>
                                            fleet is always running smoothly.</h3>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={10000} className={'slide-4'}>
                            <img src={Slide_Fourth_Desktop} alt="First slide" className={'slider-img desktop'}/>
                            <img src={Slide_Fourth_Tablet} alt="First slide" className={'slider-img tablet'}/>
                            <img src={Slide_Fourth_Mobile} alt="First slide" className={'slider-img mobile'}/>
                            <Carousel.Caption>
                                <h3 className={'main-title'}>Welcome to the<br/>Prestige Jet Club!</h3>
                                <p>We provide the best aircraft in each class without compromise. Our team of dedicated,
                                    highly qualified professionals across safety, service, operations, and more ensure
                                    that our fleet is always running smoothly.</p>
                                <button className="scroll_down_to_first_section"><img className="d-block w-100" src={ScrollDownArrow} alt="Scroll Down" className={'scroll_down_arrow'}/></button>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </section>
                <section className={'section-1'} id={"first_section"}>
                    <div className="container">
                        <div data-aos="fade-up" data-aos-delay="100" className="caption">
                            <h1 className="title">When it comes to scale and fast reservations, you can rest assured you're getting the best in the industry.</h1>
                        </div>
                        <div className="bg-img">
                            <img src={PolygonLeft} alt="" className={'leftImg'} data-aos="fade-right"/>
                            <img src={PolygonRight} alt="" className={'rightImg'} data-aos="fade-left"/>
                        </div>
                        <div className={'object_wrapper'}>
                            <img data-aos="fade-down-left" data-aos-delay="200" src={PlanImg} alt="" className={'object'}/>
                        </div>
                    </div>
                </section>
                <section className={'section-2'}>
                    <div className="bg-wrapper">
                        <img src={PlanGif} alt="" className={'bg d-lg-none'}/>
                    </div>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000" className="caption">
                                    <h1 className="title text-center">The Sky Used to Be the <br/> Limit. Now it’s Your <br/> Highway! </h1>
                                    <p className="text-center">Prestige Jet Club is the leading provider of private charter travel solutions for discerning clients worldwide. We pride ourselves on partnering only with operators who meet the same stringent safety standards as we do, ensuring that our clients can travel with peace of mind knowing that their safety is always our top priority.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img data-aos="fade-left" data-aos-delay="400" data-aos-duration="1000" src={Pilot} alt="" className={'w-100'}/>
                            </div>
                        </div>
                        <div className="content">

                        </div>
                    </div>
                </section>
                <section className={'section-3'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <img data-aos="fade-right" data-aos-duration="1000" src={Customer} alt="" className={''}/>
                            </div>
                            <div className="col-lg-6">
                                <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000" className="caption">
                                    <p>In addition, our experienced management team continually evaluates our global
                                        network of partners to make sure that they are providing the highest level of
                                        service and safety at all times. This commitment to excellence is what sets us
                                        apart from other private charter companies and is why our clients keep coming
                                        back to us time and again for all their travel needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-4'}>
                    <img src={Crew} alt="" className={'bg'}/>
                    <img  data-aos="fade-up-left" data-aos-duration="1000" src={Pilot2} alt="" className={'rightImg'}/>
                    <div className="container">
                        <div className="wrapper">
                            <div className="caption_wrapper">
                                <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000" className="caption">
                                    <p className="mb-0">We understand our clientele demands the best in private jet travel. We provide the industry's most flexible, luxurious, and proficient jet charter service. Our members enjoy access to world-class jets and a team of dedicated professionals always available to ensure a seamless experience. Prestige Jet Club is committed to providing unmatched quality of service and luxury. Our standards are just as high as yours.</p>
                                </div>
                            </div>
                            <div className="right_img_wrapper">
                                <img data-aos="fade-up-left" data-aos-duration="1000" src={Pilot2} alt="" className={'rightImgInner'}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-5'}>
                    <img data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000" src={Prestige} alt="" className={'bg'}/>
                    <div className={'sliderWrapper'}>
                        <div className="thumbSlider v1">
                            <SliderSlick1 />
                        </div>
                        <div className="thumbSlider v2">
                            <SliderSlick2 />
                        </div>
                    </div>
                    <img src={Club} alt="" className={'bg'}/>
                </section>
                <section className={'testimonials'}>
                    <img src={TestimonialBg} alt="" className={'bg'}/>
                    <div className={'container'}>
                        <SliderTestimonial />
                    </div>
                </section>
            </main>
        );
    }
}