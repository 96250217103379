import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/services_banners/page_banner_9.jpg';
// Section 16 Images ==================
import LeftSideImage from "../assets/img/jpg/services_info/services_info_9.jpg";
import PlanObject from "../assets/img/png/obj_plane_bg.png";
// Section 28 Images ==================
import specificationLeftImage from "../assets/img/jpg/specification/specification_8.jpg";
import specificationEngineImage from "../assets/img/jpg/specification_bg/specification_bg_9.jpg";
// Section 14 Images ==================
import planTopIcon from "../assets/img/png/obj_plane_circle_2.png";
import PlanDottedTop from "../assets/img/png/obj_plane_dotted_top.png";
import PlanDottedBottom from "../assets/img/png/obj_plane_dotted_top.png";
// Section 29 Images ==================
import RightSideImage from "../assets/img/jpg/commodities_bg/commodities_bg_9.jpg";
import RightSideImageBG from "../assets/img/jpg/section_bg_22.jpg";
import Icon1 from "../assets/img/png/wi-fi.png";
import Icon2 from "../assets/img/png/filming.png";
import Icon3 from "../assets/img/png/extension_board.png";
import Icon4 from "../assets/img/png/chat.png";
import Icon5 from "../assets/img/png/filter.png";
import Icon6 from "../assets/img/png/controler.png";
import Icon8 from "../assets/img/png/bluetooth.png";
import Icon9 from "../assets/img/png/ipad.png";

// Slider Images ==================
import sliderImage1 from "../assets/img/jpg/gallery/item_1.jpg";
import sliderImage2 from "../assets/img/jpg/gallery/item_17.jpg";
import sliderImage3 from "../assets/img/jpg/gallery/item_18.jpg";
import sliderImage4 from "../assets/img/jpg/gallery/item_97.jpg";
import sliderImage5 from "../assets/img/jpg/gallery/item_98.jpg";
import sliderImage6 from "../assets/img/jpg/gallery/item_99.jpg";
import sliderImage7 from "../assets/img/jpg/gallery/item_100.jpg";
import sliderImage8 from "../assets/img/jpg/gallery/item_101.jpg";
import sliderImage9 from "../assets/img/jpg/gallery/item_102.jpg";
import sliderImage10 from "../assets/img/jpg/gallery/item_103.jpg";
import sliderImage11 from "../assets/img/jpg/gallery/item_104.jpg";
import sliderImage12 from "../assets/img/jpg/gallery/item_105.jpg";
import sliderImage13 from "../assets/img/jpg/gallery/item_106.jpg";
import sliderImage14 from "../assets/img/jpg/gallery/item_16.jpg";
import sliderImage15 from "../assets/img/jpg/gallery/item_25.jpg";
import sliderImage16 from "../assets/img/jpg/gallery/item_29.jpg";
import sliderImage17 from "../assets/img/jpg/gallery/item_30.jpg";


const SliderSlick1 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
            <div>
                <img src={sliderImage2} alt=""/>
            </div>
            <div>
                <img src={sliderImage3} alt=""/>
            </div>
            <div>
                <img src={sliderImage4} alt=""/>
            </div>
            <div>
                <img src={sliderImage5} alt=""/>
            </div>
            <div>
                <img src={sliderImage6} alt=""/>
            </div>
            <div>
                <img src={sliderImage7} alt=""/>
            </div>
            <div>
                <img src={sliderImage8} alt=""/>
            </div>
        </Slider>
    );
};
const SliderSlick2 = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    speed: 6000,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],
    };
    return (
        <Slider {...settings}>
            <div>
                <img src={sliderImage9} alt=""/>
            </div>
            <div>
                <img src={sliderImage10} alt=""/>
            </div>
            <div>
                <img src={sliderImage11} alt=""/>
            </div>
            <div>
                <img src={sliderImage12} alt=""/>
            </div>
            <div>
                <img src={sliderImage13} alt=""/>
            </div>
            <div>
                <img src={sliderImage14} alt=""/>
            </div>
            <div>
                <img src={sliderImage15} alt=""/>
            </div>
            <div>
                <img src={sliderImage16} alt=""/>
            </div>
            <div>
                <img src={sliderImage17} alt=""/>
            </div>
            <div>
                <img src={sliderImage1} alt=""/>
            </div>
        </Slider>
    );
};


export default class Service9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h5 className={'sub-title'}>Large Jets</h5>
                        <h3 className={'main-title'}>Airbus A330neo</h3>
                    </div>
                </section>
                <section className={'section-16 s2'}>
                    <img src={LeftSideImage} alt="" className={'left-img'}/>
                    <div className="content">
                        <p>The A330 first took to the skies in 1992 and remains one of the most innovative aircraft today. The new engine option (NEO) has only enhanced its reputation, with customers praising its superior quality and efficiency. These enhancements have resulted in significant fuel savings and a reduced environmental impact. With its cutting-edge features and unrivaled performance, the A330 is a world-class aircraft that sets the standard.</p>
                        <p>The A330neo is designed for ultimate comfort, efficiency, and range, making it an esteemed choice for demanding VIP and private markets. The new-generation engines, aerodynamics, and other improvements deliver even more comfort, efficiency, and true nonstop worldwide range. The cabin is designed with circular tables to encourage discussion and social interaction. Guests can choose between expansive sofas and comfortable recliners, and large TV screens can be used for entertainment or presentations.</p>
                    </div>
                    <img src={PlanObject} alt="" className={'obj'}/>
                </section>
                <section className={'section-28'}>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-3">
                                <div className="content-warpper">
                                    <img src={specificationLeftImage} alt=""/>
                                    <div className="content">
                                        <h1 className={'title'}>Specifications</h1>
                                        <ul className={'list'}>
                                            <li><span>Seats</span><span>25-30</span></li>
                                            <li><span>Hours</span><span>Up to 17</span></li>
                                            <li><span>Max Cruise Speed</span><span>560 mph</span></li>
                                            <li><span>Cabin Width</span><span>17 Ft 3 in</span></li>
                                            <li><span>Cabin Height</span><span>7 Ft 91 in</span></li>
                                            <li><span>Cabin Length</span><span>147 Ft</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <img src={specificationEngineImage} alt="" className={'bg'}/>
                </section>
                <section className={'section-14 mb-0'}>
                    <div className="img-wrapper">
                        <img src={planTopIcon} alt="" className={'obj'}/>
                        <img src={PlanDottedTop} alt="" className={'top-obj'}/>
                        <img src={PlanDottedBottom} alt="" className={'bottom-obj'}/>
                    </div>
                    <div className="content">
                        <div>
                            <p>The Airbus A330Neo is an excellent choice for large corporate events. Its versatile interior and advanced comfort make it a great option for groups of all sizes. The state-of-the-art technology and entertainment systems keep passengers content, and satellite connectivity allows you to work or relax as you please. For the ultimate in luxury travel, the A330neo model offers the latest in cabin design, allowing passengers to be transported around the world like true VIPs. No matter what your event entails, the Airbus A330 will ensure your guests arrive in style and comfort.</p>
                        </div>
                    </div>
                </section>
                <section className={'section-5 s2'}>
                    <div className={'sliderWrapper'}>
                        <div className="thumbSlider v1">
                            <SliderSlick1 />
                        </div>
                        <div className="thumbSlider v2">
                            <SliderSlick2 />
                        </div>
                    </div>
                </section>
                <section className={'section-29'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <h1 className="main-title">Commodities</h1>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon1} alt="" className={'icon'}/>
                                            <p>High-Speed International
                                                Wi-Fi</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon2} alt="" className={'icon'}/>
                                            <p>On-Demand In-Flight Entertainment System</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon3} alt="" className={'icon'}/>
                                            <p>Universal Power Outlets Accessible at Every Seat</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon4} alt="" className={'icon'}/>
                                            <p>Text & Talk</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon5} alt="" className={'icon'}/>
                                            <p>HEPA Air Filter + Humidifier</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon6} alt="" className={'icon'}/>
                                            <p>Touch-Screen Cabin Controls</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon9} alt="" className={'icon'}/>
                                            <p>iPad <br/>Tablets</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="card">
                                            <img src={Icon8} alt="" className={'icon'}/>
                                            <p>Bluetooth <br/>Technology</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'right-img'}>
                        <img src={RightSideImage} alt=""/>
                        <img src={RightSideImageBG} alt="" className={'bg'}/>
                    </div>
                    <img src={RightSideImageBG} alt="" className={'bg'}/>
                </section>
            </main>
        );
    }
}