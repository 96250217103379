import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';

// Images ==================
import BannerImage1 from './../assets/img/jpg/page_banners/page_banner_1.jpg';
import TestimonialBG from './../assets/img/jpg/section_bg_3.jpg';
// import cardObj from './../assets/img/jpg/card_bg_1.jpg';
import ThumbSlider1 from './../assets/img/jpg/charter_slides/charter_slide_1.jpg';
import ThumbSlider2 from './../assets/img/jpg/charter_slides/charter_slide_2.jpg';
import ThumbSlider3 from './../assets/img/jpg/charter_slides/charter_slide_3.jpg';
import ThumbSlider4 from './../assets/img/jpg/charter_slides/charter_slide_4.jpg';
import ThumbSlider5 from './../assets/img/jpg/charter_slides/charter_slide_5.jpg';
import ThumbSlider6 from './../assets/img/jpg/charter_slides/charter_slide_6.jpg';
import ThumbSlider7 from './../assets/img/jpg/charter_slides/charter_slide_7.jpg';


export default class Page1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            loader: false,
        };
    }

    componentDidMount() {
        this.loaderShow();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            nav3: this.slider3,
            nav4: this.slider4,
        });
    }

    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };



    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage1} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>At Prestige Jet Club, we understand that every <br/> traveler has unique needs when it comes to flying.</h3>
                    </div>
                </section>
                <section className={'section-7'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={'sliderWrppaer'}>
                                    <Slider className={'slider d-md-none'} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} slidesToShow={1} arrows={false} centerMode={false} swipeToSlide={true} focusOnSelect={true}>
                                        <div>
                                            <img src={ThumbSlider1} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider2} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider3} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider4} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider5} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider6} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider7} alt=""/>
                                        </div>
                                    </Slider>
                                    <Slider className={'slider-thumbnails d-md-none'}  asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)} slidesToShow={2} slidesToScroll={1} centerMode={false} swipeToSlide={true} focusOnSelect={true}>
                                        <div>
                                            <img src={ThumbSlider1} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider2} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider3} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider4} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider5} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider6} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider7} alt=""/>
                                        </div>
                                    </Slider>
                                    <Slider className={'slider d-none d-md-block'} rtl={true} asNavFor={this.state.nav4} ref={slider => (this.slider3 = slider)} slidesToShow={1} arrows={false} centerMode={false} swipeToSlide={true} focusOnSelect={true}>
                                        <div>
                                            <img src={ThumbSlider2} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider3} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider4} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider5} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider6} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider7} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider1} alt=""/>
                                        </div>
                                    </Slider>
                                    <Slider className={'slider-thumbnails d-none d-md-block'} rtl={true} asNavFor={this.state.nav3} ref={slider => (this.slider4 = slider)} slidesToShow={4} slidesToScroll={1} centerMode={false} swipeToSlide={true} focusOnSelect={true}>
                                        <div>
                                            <img src={ThumbSlider1} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider2} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider3} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider4} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider5} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider6} alt=""/>
                                        </div>
                                        <div>
                                            <img src={ThumbSlider7} alt=""/>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={'contentWrapper'}>
                                    <div className="card content-card">
                                        {/*<img src={cardObj} alt="" className={'object'}/>*/}
                                        <p>That's why we've created a charter service that is convenient, flexible, and value-driven. When you fly with us, you'll have access to private jets backed by operational expertise. We accommodate trips for members and first-time flyers alike.
                                            <br/>
                                            <br/>
                                            And because we know your time is valuable, we have a team of advisors ready to help you build a custom travel itinerary.
                                            <br/>
                                            <br/>
                                            Whether you're looking for a luxurious getaway or a business trip, Prestige Jet Club is here to make your travel dreams a reality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-6 quote-section'}>
                    <img src={TestimonialBG} alt="" className={'bg'}/>
                    <Container>
                        <div className="row align-items-center">
                            <div className="col-lg-4 px-lg-0">
                                <div className="card">
                                    <h1 className="title">Experience the <br/> Benefits of Prestige Private Travel!</h1>
                                    <p>As a member, Prestige Jet Club provides you with catered rewards for flying with us. From preferred customer service to dedicated discounts, we’re serious about making our habitués as comfortable as possible.</p>
                                    <a className={'link'}>Find Out More About Our Exclusive Offers</a>
                                </div>
                            </div>
                            <div className="col-lg-8 px-lg-0">
                                <div className="form_wrapper">
                                    <h1 className="main-title">Request Your Charter</h1>
                                    <iframe className={'get_quote_iframe'} src="https://link.rewardlion.com/widget/form/gpFMpGgGxaSwXtlJirPc" id="inline-gpFMpGgGxaSwXtlJirPc" data-layout="{'id':'INLINE'}" data-trigger-type="alwaysShow" data-trigger-value="" data-activation-type="alwaysActivated" data-activation-value="" data-deactivation-type="neverDeactivate" data-deactivation-value="" data-form-name="Request your charter" data-height="2027" data-layout-iframe-id="inline-gpFMpGgGxaSwXtlJirPc" data-form-id="gpFMpGgGxaSwXtlJirPc">
                                    </iframe>
                                    <script src="https://link.rewardlion.com/js/form_embed.js"></script>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
        );
    }
}