import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_single_2.jpg';
// Section 17 Images ==================
import Ladysuitecase from './../assets/img/jpg/getting_on_a_plane_with_luggage_3.jpg';
import Pilot2 from './../assets/img/jpg/pilot_and_hostess_looking_in_sky_2.jpg';
import PilotWithLadies from './../assets/img/jpg/pilot_and_hostess.jpg';
import Plan3 from './../assets/img/png/obj_plane_3.png';
import BG3 from "../assets/img/jpg/section_bg_11.jpg";
// Section 2 Images ==================
import PlanSide3 from "../assets/img/jpg/plane_with_sunset.jpg";





export default class Page8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero s2'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className="container">
                        <div className={'caption'}>
                            <h3 className={'main-title'}>Taking Flight with Prestige Jet Club Courses</h3>
                            <p>Are you interested in taking your love of aviation to the next level? Then look no further than Prestige Jet Club Courses! With our professional instructors and up-to-date aircraft, we are sure to provide you with an excellent platform for learning to fly. Whether you are looking for a recreational activity or want to become a pilot on an airline, Prestige Jet Courses have all the services necessary for fulfilling each individual's needs. Learn how easy it is to take flight and how our courses can help make that possible!</p>
                        </div>
                    </div>
                </section>
                <section className={'section-26'}>
                    <div className="container">
                        <img src={Ladysuitecase} alt="" className={'img'}/>
                        <div className="content">
                            <div>
                                <h1 className="title">Why Choose Prestige Jet Club Courses?</h1>
                                <p>At Prestige Jet Club Courses, we understand that everyone has different personal goals when it comes to aviation. We offer a variety of classes ranging from basic flying lessons, multi-engine training, instrument rating preparation, and more. Our knowledgeable instructors will provide training in a safe environment so that students can learn at their own pace while still meeting all standards set by the Federal Aviation Administration (FAA). This allows us to tailor our courses to meet each individual's needs.</p>
                                <p>In addition to providing exceptional instruction, we only use top-of-the-line aircraft equipped with the latest technology. Our students experience modern aviation firsthand as they learn about the basics of flight. Furthermore, each plane is regularly inspected and maintained in accordance with FAA regulations so that safety remains paramount throughout the course duration.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-17'}>
                    <img src={BG3} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h1 className={'title'}>Our Commitment To Excellence</h1>
                                    <p>At Prestige Jet Club Courses, we strive for excellence in everything we do. We believe that it is essential for our students to have access to comprehensive instruction from experienced professionals who can help them reach their goals. That's why all our instructors are highly trained and certified by the FAA to ensure quality instruction at every level. We also make sure that every student receives one-on-one attention during their lessons and personalized feedback on their progress throughout the course duration.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={Pilot2} alt="" className={'img-1'}/>
                                    <img src={PilotWithLadies} alt="" className={'img-2'}/>
                                    <img src={Plan3} alt="" className={'obj'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={'section-2 s2'}>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <img src={PlanSide3} alt="" className={'img-fluid'}/>
                            </div>
                            <div className="col-lg-6">
                                <div className="caption ps-lg-5">
                                    <h1 className="title">Diamond Elite Card</h1>
                                    <p>Enjoy all the benefits of Prestige Jet Club membership with an Elite Card, such as access to our worldwide network of hand-selected aircraft operators and 24/7 concierge services. Our specially-crafted package allows Members to experience more flexible rates and additional discounts on select services and products. Take your travel plans to new heights with our Elite Card!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}